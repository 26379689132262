import { Beta, Stack, Button } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';

type EmptyTableProps = {
  onClick?: () => void;
  entity?: string;
  action?: string;
  icon?: Parameters<typeof Beta.Icon>[0]['icon'];
};

export const EmptyTable = ({
  onClick,
  entity,
  action,
  icon = 'file',
}: EmptyTableProps) => {
  const { t } = useTranslation();
  const entityLabel = entity ?? t('common.data');
  const actionLabel = action ?? t('common.add');

  return (
    <Stack align="center" pt={80}>
      <Beta.Icon icon={icon} />
      <Beta.Text fw="bold">
        {t('table.empty', { entity: entityLabel })}
      </Beta.Text>

      <Button variant="outline" onClick={onClick}>
        {t('table.addAction', { action: actionLabel, entity: entityLabel })}
      </Button>
    </Stack>
  );
};
