import {
  Button,
  Center,
  Group,
  Select,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import * as yup from 'yup';
import { useFetchApi, useIntlApi } from '../../hooks';
import { useSurvey } from '../../hooks/useSurvey';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponses } from '../../hooks/useSurveyResponses';
import { PageNotFoundPage } from '../../shared/components/PageNotFoundPage';
import { Attachment } from '../../shared/types/Attachment';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';

type FileUploadValue = {
  /** we define how the `content` property looks like on upload of a file. */
  content: { attachmentId: string };
  /** file name, e.g. financialreport.pdf */
  name: string;
}[];
const fileUploadValueSchema = yup.array(
  yup.object({
    content: yup.object({ attachmentId: yup.string().required() }).required(),
    name: yup.string().required(),
  })
);
const isFileUploadValue = (x: unknown): x is FileUploadValue => {
  try {
    fileUploadValueSchema.validateSync(x);
    return true;
  } catch (error) {
    return false;
  }
};

export const CreateSurveyResponsePage = () => {
  const { surveyId } = useParams();
  const { data: survey, isLoading } = useSurvey(surveyId);
  const [attachmentIds, setAttachmentIds] = useState<string[]>([]);
  const { t } = useTranslation();
  const { createSurveyResponse } = useSurveyResponses(surveyId, {
    disableGet: true,
  });
  const [country, setCountry] = useState<string>();
  const fetchFileUpload = useFetchApi();
  const surveyModel = useSurveyJsModel(survey?.json, undefined, undefined);
  useEffect(() => {
    if (surveyModel) {
      const avoidDuplicateListeners = () => {
        surveyModel.onUploadFiles.clear();
        surveyModel.onClearFiles.clear();
        surveyModel.onDownloadFile.clear();
      };

      avoidDuplicateListeners();

      surveyModel.onUploadFiles.add(async (_, options) => {
        const attachmentPromises = options.files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          const attachment = (await fetchFileUpload('attachments', {
            method: 'POST',
            body: formData,
            headers: { 'Content-Type': undefined },
          })) as Attachment;
          const fileWithNewName = new File([], attachment.filename, {
            type: file.type,
          });
          return { attachment, file: fileWithNewName };
        });
        // TODO TEUDR-2292 error handling
        const errorMessages: string[] = [];

        const attachments = await Promise.all(attachmentPromises);
        const newAttachmentIds = attachments.map((a) => a.attachment.id);
        setAttachmentIds((prev) => [...prev, ...newAttachmentIds]);
        return options.callback(
          attachments.map((a) => ({
            file: a.file,
            content: { attachmentId: a.attachment.id },
          })),
          errorMessages
        );
      });

      surveyModel.onClearFiles.add(async (_, options) => {
        const clearAllButtonClicked = options.fileName === null;
        if (clearAllButtonClicked) {
          // TODO TEUDR-1088 on the create survey response page: when a user clicks delete, we should call DELETE /attachments/:id immediately (there is no survey response that the attachment is part of yet)
          setAttachmentIds([]);
          return options.callback('success');
        }
        // user deleted a single file
        if (!isFileUploadValue(options.value)) {
          console.error(
            `CreateSurveyResponsePage: Unexpected file upload value in survey response when deleting individual file. Skipping deletion logic. Survey id: ${survey?.id}. question name inside survey.json: ${options.name}.`
          );
          return;
        }
        const deletedAttachment = options.value.find(
          (val) => val.name === options.fileName
        );
        // TODO TEUDR-1088 on the create survey response page: when a user clicks delete, we should call DELETE /attachments/:id immediately (there is no survey response that the attachment is part of yet)
        setAttachmentIds((prev) =>
          prev.filter((id) => id !== deletedAttachment?.content.attachmentId)
        );
        return options.callback('success');
      });
      // TODO TEUDR-2292. Do we even want to allow individual download of files? In any case, enabling this now causes the upload to never show file icon indicating that the file upload finished. For Create, we can just get the file from memory. For Edit: This should probably call GET /attachments?presign=true and then fetch the blob from the GetAttachmentDto.fileUrl + trigger download thereof.
      // surveyModel.onDownloadFile.add(async (_) => {});
    }
  }, [surveyModel, fetchFileUpload, setAttachmentIds, survey?.id]);
  const { localizedCountryList } = useIntlApi();

  const save = () => {
    if (!surveyModel) return;
    const surveyResponseJson = surveyModel.data;
    // Note: this will navigate away on success
    createSurveyResponse.execute({
      json: surveyResponseJson,
      countryCode: country ?? undefined,
      attachmentIds,
    });
  };

  if (isLoading) return <TemporaryLoadingPage />;
  if (!survey) return <PageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  // TODO TEUDR-1696 make this look nice
  return (
    <Center>
      <Stack w="100vw">
        <Stack
          w="100vw"
          maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
          mx={'auto'}
        >
          <Title ta="center" order={1} size="h2">
            {survey.name}
          </Title>
          <Text fs={'italic'}>{t('documents.create.info')}</Text>
          <Select
            label={t('documents.create.country')}
            placeholder={t<string>('documents.create.country')}
            data={localizedCountryList}
            searchable
            onChange={(val) => setCountry(val ?? undefined)}
            styles={{
              root: { backgroundColor: 'var(--mantine-color-body)' },
              wrapper: { backgroundColor: 'var(--mantine-color-body)' },
              input: { backgroundColor: 'var(--mantine-color-body)' },
              dropdown: { backgroundColor: 'var(--mantine-color-body)' },
            }}
          />
          <Survey model={surveyModel} />
        </Stack>

        <Group justify="flex-end" p="md">
          <Button
            variant="outline"
            w="175px"
            component={Link}
            to={'/map/documents?pan=1'}
          >
            {t('common.close')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            onClick={save}
            loading={createSurveyResponse.isLoading}
          >
            {t('common.saveAndClose')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            loading={createSurveyResponse.isLoading}
          >
            {t('common.submit')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
