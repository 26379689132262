import { Button, Flex } from '@liveeo/component-library';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { HsCode } from '../../../shared/types/Transaction';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTransactions } from '../../../hooks/useTransactions';

const DEFAULT_COMPONENT = {
  commodity: '',
  description: '',
  hsn: '',
  namePairs: [
    {
      commonName: '',
      scientificName: '',
    },
  ],
  quantity: 0,
  supplierBusiness: {
    id: '',
  },
  unit: 'KGM',
};

export const HsCodeFormButtons = <T extends FieldValues>({
  field,
  setIsModalOpen,
}: {
  field: FieldValues;
  hsCodes: HsCode[];
  setIsModalOpen: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const { hsCodes } = useTransactions();
  const components = getValues('components');

  return (
    <Flex justify="end" gap="sm" mt="lg">
      <Button variant="outline" size="sm" onClick={() => setIsModalOpen(false)}>
        {t('common.cancel')}
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={() => {
          setValue('selectedHsCodes' as Path<T>, field.value);
          const existingComponents =
            components?.map(({ hsn }: { hsn: string }) => hsn) ?? [];
          const newHsCodes = field.value.filter(
            (hsCode: string) => !existingComponents.includes(hsCode)
          );
          const newComponents =
            hsCodes
              ?.filter((component) => newHsCodes.includes(component.hsCode))
              ?.map((component) => ({
                ...DEFAULT_COMPONENT,
                commodity: component.commodity,
                description: component.description,
                hsn: component.hsCode,
              })) ?? [];

          setValue('components', [...(components ?? []), ...newComponents]);
          setIsModalOpen(false);
        }}
      >
        {t('common.save')}
      </Button>
    </Flex>
  );
};
