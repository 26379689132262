import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex } from '@mantine/core';
import {
  TransactionFormData,
  TransactionStatus,
} from '../../../shared/types/Transaction';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTransactions } from '../../../hooks';

type TransactionFormButtonProps = {
  setValue: UseFormSetValue<TransactionFormData>;
  transactionUrl: string;

  disabled: boolean;
};

export const TransactionFormButton = ({
  setValue,
  transactionUrl,
  disabled,
}: TransactionFormButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit, reset } = useFormContext<TransactionFormData>();
  const { createTransaction, submitTransaction, saveAndSubmitTransaction } =
    useTransactions();
  const { transactionId } = useParams();
  const isNewTransaction = transactionId === 'new';

  const onSuccess = () => {
    reset();
    navigate(transactionUrl);
  };

  const handleSaveTransaction = handleSubmit(
    ({ selectedHsCodes, ...transactionData }) => {
      setValue('status', TransactionStatus.DRAFT);
      createTransaction.mutate(transactionData, { onSuccess });
    }
  );

  const handleSubmitTransaction = handleSubmit(
    ({ selectedHsCodes, ...transactionData }) => {
      submitTransaction.mutate(transactionData, { onSuccess });
    }
  );

  const handleSaveAndSubmitTransaction = handleSubmit(
    ({ selectedHsCodes, ...transactionData }) => {
      saveAndSubmitTransaction.mutate(transactionData, { onSuccess });
    }
  );

  return (
    <Flex justify="end" gap="sm" mt="lg">
      <Button
        variant="outline"
        size="sm"
        onClick={() => navigate(transactionUrl)}
      >
        {t('common.cancel')}
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={handleSaveTransaction}
        disabled={disabled}
      >
        {t('common.saveAndClose')}
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={
          isNewTransaction
            ? handleSaveAndSubmitTransaction
            : handleSubmitTransaction
        }
        disabled={disabled}
      >
        {t('common.submit')}
      </Button>
    </Flex>
  );
};
