import { Flex, ScrollArea, Table } from '@mantine/core';
import { Beta } from '../..';
import { TableContainerStyled } from './DataGrid.styled';
import { DataGridProps } from './DataGrid.interface';
import get from 'lodash.get';

export const DataGrid = ({
  data,
  columns,
  onSelect = () => ({}),
  onSort = () => ({}),
  sortStatus,
  keyExtractor = (_, index) => `${index}`,
  verticalSpacing = 'sm',
  horizontalSpacing = 'sm',
  ...props
}: DataGridProps) => {
  return (
    <TableContainerStyled {...props}>
      <ScrollArea
        h="100%"
        offsetScrollbars
        p={0}
        styles={{
          viewport: {
            paddingRight: 0,
          },
        }}
      >
        <Table
          stickyHeader
          verticalSpacing={verticalSpacing}
          horizontalSpacing={horizontalSpacing}
          borderColor={'dark.9'}
          highlightOnHover
        >
          <Table.Thead>
            <Table.Tr bg="dark.9">
              {columns.map((column, index) => (
                <Table.Th
                  onClick={() => {
                    if (column.hasSorting) {
                      if (sortStatus === '') {
                        onSort(column.accessor);
                      } else if (sortStatus === column.accessor) {
                        onSort('-' + String(column.accessor));
                      } else {
                        onSort(column.accessor);
                      }
                    }
                  }}
                  style={{ cursor: column.hasSorting ? 'pointer' : 'default' }}
                  key={`table-th-${index}`}
                >
                  <Flex
                    align="center"
                    direction="row"
                    gap={20}
                    justify={column.align ?? 'left'}
                  >
                    <Beta.Text variant="b1" fw="bold">
                      {column.header}
                    </Beta.Text>
                    {column.hasSorting && (
                      <Beta.Icon
                        size="sm"
                        icon={(() => {
                          if (sortStatus === column.accessor)
                            return 'arrow-down';
                          if (sortStatus === `-${String(column.accessor)}`)
                            return 'arrow-up';
                          return 'arrow-down';
                        })()}
                        color={(() => {
                          if (
                            sortStatus === column.accessor ||
                            sortStatus === `-${String(column.accessor)}`
                          )
                            return 'dark.5';
                          return 'white';
                        })()}
                      />
                    )}
                  </Flex>
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.map((row, rowIndex) => (
              <Table.Tr
                key={`table-tr-${keyExtractor(row, rowIndex)}`}
                onClick={() => onSelect(row.id)}
                style={{
                  // mantine <Table highlightOnHoverColor="red"> is not working due to higher specificity of :where(tr)[data-hover]. See https://github.com/mantinedev/mantine/issues/5909
                  '--tr-hover-bg': 'var(--mantine-color-dark-8)',
                }}
              >
                {columns.map((column, colIndex) => (
                  <Table.Td
                    key={`table-td-${colIndex}`}
                    ta={column.align ?? 'left'}
                  >
                    {column.cell ? (
                      <column.cell
                        column={column}
                        getValue={() =>
                          get(row, column.accessor, column.fallback ?? null)
                        }
                        row={row}
                      />
                    ) : (
                      <Beta.Text variant="b2" ta={column.align ?? 'left'}>
                        {/* allow nested property access. if not found, fallback to null */}
                        {get(row, column.accessor, column.fallback ?? null)}
                      </Beta.Text>
                    )}
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </TableContainerStyled>
  );
};
