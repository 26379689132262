import {
  Button,
  Center,
  Group,
  Select,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import * as yup from 'yup';
import { useFetchApi, useIntlApi, useNotification } from '../../hooks';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponse } from '../../hooks/useSurveyResponse';
import { PageNotFoundPage } from '../../shared/components/PageNotFoundPage';
import { Attachment } from '../../shared/types/Attachment';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';

// TODO TEUDR-1760 avoid duplication with CreateSurveyResponsePage
type FileUploadValue = {
  /** we define how the `content` property looks like on upload of a file. */
  content: { attachmentId: string };
  /** file name, e.g. financialreport.pdf */
  name: string;
}[];
const fileUploadValueSchema = yup.array(
  yup.object({
    content: yup.object({ attachmentId: yup.string().required() }).required(),
    name: yup.string().required(),
  })
);
const isFileUploadValue = (x: unknown): x is FileUploadValue => {
  try {
    fileUploadValueSchema.validateSync(x);
    return true;
  } catch (error) {
    return false;
  }
};

export const EditSurveyResponsePage = () => {
  const { surveyResponseId } = useParams();
  const { t } = useTranslation();
  const {
    data: surveyResponse,
    isLoading: surveyResponseIsLoading,
    updateSurveyResponse,
    submitSurveyResponse,
  } = useSurveyResponse(surveyResponseId);
  const [attachmentIds, setAttachmentIds] = useState<string[]>([]);
  useEffect(() => {
    if (surveyResponse)
      setAttachmentIds(surveyResponse.attachments?.map((a) => a.id) ?? []);
  }, [surveyResponse]);
  const { successfullyUpdated } = useNotification();
  const [country, setCountry] = useState<string>();
  useEffect(() => {
    setCountry(surveyResponse?.countryCode);
  }, [surveyResponse?.countryCode, setCountry]);
  const navigate = useNavigate();
  const surveyModel = useSurveyJsModel(
    surveyResponse?.survey?.json,
    surveyResponse?.json,
    surveyResponse?.status
  );
  const fetchFileUpload = useFetchApi();
  useEffect(() => {
    if (surveyModel) {
      const avoidDuplicateListeners = () => {
        surveyModel.onUploadFiles.clear();
        surveyModel.onClearFiles.clear();
        surveyModel.onDownloadFile.clear();
      };

      avoidDuplicateListeners();
      surveyModel.onUploadFiles.add(async (sender, options) => {
        // options.files only includes the newly added files - which may be multiple if allowMultiple is enabled in the survey.json. options.files does NOT contain the files that have already been uploaded before.
        // Duplicate file uploads are however possible if the user selects an existing file for upload at a later point again. We accept this behavior. It is up to the user to decide what they upload - and how often.
        const attachmentPromises = options.files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          const attachment = (await fetchFileUpload('attachments', {
            method: 'POST',
            body: formData,
            headers: {
              'Content-Type': undefined,
            },
          })) as Attachment;
          const fileWithNewName = new File([], attachment.filename, {
            type: file.type,
          });
          return { attachment, file: fileWithNewName };
        });
        // TODO TEUDR-2292 error handling
        const errorMessages: string[] = [];

        const attachments = await Promise.all(attachmentPromises);
        const newAttachmentIds = attachments.map((a) => a.attachment.id);
        setAttachmentIds((prev) => [...prev, ...newAttachmentIds]);
        return options.callback(
          attachments.map((a) => ({
            file: a.file,
            // surveyjs seems to be very specific about the property name `content` to put it inside the survey response json. Everything else is being ignored
            content: { attachmentId: a.attachment.id },
          })),
          errorMessages
        );
      });

      surveyModel.onClearFiles.add(async (_, options) => {
        const clearAllButtonClicked = options.fileName === null;
        if (clearAllButtonClicked) {
          setAttachmentIds([]);
          return options.callback('success');
        }

        // user deleted a single file
        if (!isFileUploadValue(options.value)) {
          console.error(
            `EditSurveyResponsePage: Unexpected file upload value in survey response when deleting individual file. Skipping deletion logic. Survey id: ${surveyResponse?.survey?.id}. question name inside survey.json: ${options.name}.`
          );
          return;
        }
        const deletedAttachment = options.value.find(
          (val) => val.name === options.fileName
        );
        // TODO TEUDR-1088 on the edit survey response page: when a user clicks delete, we should save the attachmentids that need to be deleted (or better, diff on surveyresponse.attachmentIds and component state attachmentIds variable). Once the user hits save, we unlink the attachments from the survey response by PATCH /survey-responses/:id, and then call DELETE /attachments/:id immediately (potentially failing silently if there is another survey response that the attachment is part of yet - which is theoretically possible but realistically not at this point in time)
        setAttachmentIds((prev) =>
          prev.filter((id) => id !== deletedAttachment?.content.attachmentId)
        );
        return options.callback('success');
      });
      // TODO TEUDR-2292. Do we even want to allow individual download of files? In any case, enabling this now causes the upload to never show file icon indicating that the file upload finished. For Create, we can just get the file from memory. For Edit: This should probably call GET /attachments?presign=true and then fetch the blob from the GetAttachmentDto.fileUrl + trigger download thereof.
      // surveyModel.onDownloadFile.add(async (_) => {});
    }
  }, [
    surveyModel,
    fetchFileUpload,
    setAttachmentIds,
    surveyResponse?.survey?.id,
  ]);
  const { localizedCountryList } = useIntlApi();

  const navigateToDocumentsTable = () => navigate('/map/documents?pan=1');
  const save = async () => {
    const surveyResponseJson = surveyModel?.data;
    await updateSurveyResponse.execute({
      json: surveyResponseJson,
      countryCode: country,
      attachmentIds,
    });
  };
  const saveAndClose = async () => {
    await save();
    // TODO TEUDR-1760 fix notifications
    successfullyUpdated('survey response');
    navigateToDocumentsTable();
  };
  const submit = async () => {
    await save();
    await submitSurveyResponse.execute({
      status: 'COMPLETED',
    });
    // TODO TEUDR-1760 fix notifications
    successfullyUpdated('survey response');
    navigateToDocumentsTable();
  };

  if (surveyResponseIsLoading) return <TemporaryLoadingPage />;
  if (!surveyResponse) return <PageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  const isCompleted = surveyResponse.status === 'COMPLETED';

  // TODO TEUDR-1760 make this look nice
  // TODO TEUDR-1760 avoid duplication with CreateSurveyResponsePage
  return (
    <Center>
      <Stack w="100vw">
        <Stack
          w="100vw"
          maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
          mx={'auto'}
        >
          <Title ta="center" order={1} size="h2">
            {surveyResponse?.survey?.name}
          </Title>
          <Text fs={'italic'}>{t('documents.create.info')}</Text>
          <Select
            label={t('documents.create.country')}
            placeholder={t<string>('documents.create.country')}
            value={country}
            data={localizedCountryList}
            searchable
            onChange={(val) => setCountry(val ?? undefined)}
            styles={{
              root: { backgroundColor: 'var(--mantine-color-body)' },
              wrapper: { backgroundColor: 'var(--mantine-color-body)' },
              input: { backgroundColor: 'var(--mantine-color-body)' },
              dropdown: { backgroundColor: 'var(--mantine-color-body)' },
            }}
            disabled={isCompleted}
          />
          <Survey model={surveyModel} />
        </Stack>
        <Group justify="flex-end" p="md">
          <Button
            variant="outline"
            w="175px"
            component={Link}
            to={'/map/documents?pan=1'}
          >
            {t('common.close')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            onClick={saveAndClose}
            loading={updateSurveyResponse.isLoading}
            display={isCompleted ? 'none' : undefined}
          >
            {t('common.saveAndClose')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            loading={updateSurveyResponse.isLoading}
            onClick={submit}
            display={isCompleted ? 'none' : undefined}
          >
            {t('common.submit')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
