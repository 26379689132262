import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

const API_URL = `${
  import.meta.env['NX_EUDR_DEFORESTATION_MONITORING_SERVICE_BASE_URL'] || ''
}`;

type EnhancedRequestInit = Omit<RequestInit, 'headers'> & {
  headers?: Record<string, string | undefined>;
};

export const useFetchApi = (responseType: 'blob' | 'json' = 'json') => {
  const { getAccessTokenSilently, logout } = useAuth0();

  return async (path: string, init?: EnhancedRequestInit) => {
    const token = await getAccessTokenSilently();
    const headers = shallowCopyWithoutUndefinedValue({
      'Content-Type': 'application/json',
      ...(token ? { authorization: `Bearer ${token}` } : undefined),
      ...init?.headers,
    });
    const response = await fetch(`${API_URL}/${path}`, {
      ...init,
      headers,
    });

    if (token && response.status === 401) {
      Sentry.captureMessage('401 Unauthorized access');
      logout();
    }

    if (!response.ok) {
      const error: any = await response.json();
      Sentry.captureException(error.message);
      throw new Error(error?.message);
    }

    if (response.status === 204) {
      return response;
    }

    return response[responseType]();
  };
};

const shallowCopyWithoutUndefinedValue = <T extends object>(obj: T) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    return { ...acc, [key]: value };
  }, {});
};
