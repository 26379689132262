import { Beta, Button, Menu } from '@liveeo/component-library';
import classes from '../../../shared/styles/Menu.module.css';
import clsx from 'clsx';

export type ActionItem = {
  label: string;
  onClick: () => void;
};

type ActionsMenuProps = {
  list: ActionItem[];
};

const ActionsMenu = ({ list }: ActionsMenuProps) => {
  return (
    <Menu offset={-20} position="left-start" additionalClassNames={classes}>
      <Menu.Target>
        <Button
          variant="transparent"
          size="sm"
          className={clsx(!list?.length && classes.hidden)}
        >
          <Beta.Icon icon="ellipsis-vertical" />
        </Button>
      </Menu.Target>
      <Menu.Dropdown bg="dark.9" opacity={0.9}>
        <>
          {list?.map((item) => (
            <Menu.Item onClick={item.onClick} key={item.label}>
              {item.label}
            </Menu.Item>
          ))}
        </>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ActionsMenu;
