import { Beta, DataGrid, Stack, Button, Box } from '@liveeo/component-library';
import { Table } from '../Table';
import { EmptyTable } from './EmptyTable';
import useColumns, { Column } from './useColumns';
import useSort from './useSort';
import { GenericErrorBoundary } from '../GenericErrorBoundary';
import { SidePanelContainer } from '../../../pages/Map/SidePanelContainer';
import classes from './NewTable.module.css';
type NewTableProps<T> = {
  columns: Column<T>[];
  data: T[] | undefined;
  loader?: {
    height?: number | string;
    loading?: boolean;
  };
  empty?: {
    entity?: string;
    action?: string;
    onClick?: () => void;
    icon?: Parameters<typeof Beta.Icon>[0]['icon'];
  };
  title?: string;
  onAdd?: () => void;
};

const NewTable = <T,>({
  columns,
  data = [],
  loader,
  empty,
  title,
  onAdd,
}: NewTableProps<T>) => {
  const { sort, sortedData, handleSort } = useSort(data);

  const tableColumns = useColumns<T>(columns);
  if (loader?.loading) {
    return <Table.Loader height={loader?.height ?? '100dvh'} />;
  }

  return (
    <GenericErrorBoundary>
      <Stack gap={0}>
        {title && (
          <Box className={classes.title}>
            <SidePanelContainer.Title title={title} />
            {onAdd && (
              <Button
                variant="transparent"
                size="sm"
                onClick={onAdd}
                data-testid="add-button"
              >
                <Beta.Icon icon="plus" />
              </Button>
            )}
          </Box>
        )}

        <DataGrid
          data={sortedData}
          columns={tableColumns}
          sortStatus={sort}
          onSort={handleSort}
          verticalSpacing={8}
          horizontalSpacing={16}
        />
        {data.length === 0 && <EmptyTable {...empty} />}
      </Stack>
    </GenericErrorBoundary>
  );
};

export default NewTable;
