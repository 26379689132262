import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useStartAnalysis, useFetchApi } from './';
import { AddPlot, Plot } from '../shared/types';
import { useCallback, useMemo } from 'react';
import { useNotification } from './useNotification';
import { useLocation, useNavigate } from 'react-router-dom';
import { deforestationRiskMapping } from '../helper';
import { usePlotsContext } from '../contexts/PlotsContext';

export const plotsToPages = (plots: Plot[]) => {
  const PAGE_SIZE = 250;
  const pages: Plot[][] = [];
  for (let i = 0; i < plots.length; i += PAGE_SIZE) {
    pages.push(plots.slice(i, i + PAGE_SIZE));
  }
  return pages;
};

export const updatePlotsAfterCreation = (
  prevPlots: InfiniteData<Plot[]> | undefined,
  plot: Plot
) => {
  if (!prevPlots) return prevPlots;

  let allPlots: Plot[] = [];

  // Collect all plots from all pages
  prevPlots.pages.forEach((page) => {
    allPlots = [...allPlots, ...page];
  });

  // Add new plot at the start
  allPlots = [plot, ...allPlots];

  // Redistribute plots into pages of 250
  const newPages = plotsToPages(allPlots);

  return {
    pageParams: prevPlots.pageParams,
    pages: newPages,
  };
};

export const usePlots = () => {
  const { data, error, isLoading } = usePlotsContext();
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyCreated } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: startAnalysis } = useStartAnalysis();

  const createPlot = useMutation({
    mutationKey: ['plots'],
    mutationFn: (payload: AddPlot) =>
      fetch('plots', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    onSuccess: (plot: Plot) => {
      successfullyCreated('plot');

      startAnalysis({ plot, type: 'EUFOROBS' });

      queryClient.setQueryData<InfiniteData<Plot[]>>(['plots'], (prevPlots) =>
        updatePlotsAfterCreation(prevPlots, plot)
      );

      navigate({
        pathname: '/map/plots',
        search: `${location.search}&ex=0`,
      });
    },
    onError: apiError,
  });

  const findPlotById = useCallback(
    (plotId: string) => {
      if (!plotId) return;
      const plot = data?.find((plot: Plot) => plot.id === plotId);
      queryClient.setQueryData(['plot'], plot);
      return plot;
    },
    [data, queryClient]
  );

  const plotsAsFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: data?.map((plot: Plot, i: number) => ({
        id: i, // Mapbox requires the feture id to be a number so we can't use the plotId
        type: 'Feature',
        geometry: plot.geometry,
        properties: {
          plotId: plot.id,
          riskOfDeforestation: deforestationRiskMapping(
            plot?.mostRecentAnalysesByType?.PRECISION?.riskOfDeforestation ??
              plot?.mostRecentAnalysesByType?.EUFOROBS?.riskOfDeforestation
          ),
        },
      })),
    };
  }, [data]);

  return {
    createPlot,
    data,
    error,
    findPlotById,
    isLoading,
    plotsAsFeatureCollection,
  };
};
