import { useMemo } from 'react';
import ActionsMenu, { ActionItem } from './ActionMenu';
import { Text } from '@liveeo/component-library';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

export type Column<T> = {
  value: string;
  label?: string;
  sort?: boolean;
  renderer?: (row: T) => JSX.Element;
  align?: 'left' | 'right' | 'center';
  setActions?: (row: T) => ActionItem[];
  translationPath?: string;
};

const useColumns = <T,>(columns: Column<T>[]) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      columns.map(
        ({ value, label, sort, align, setActions, translationPath }) => ({
          id: value,
          accessor: value,
          cell: ({ row }: { row: T }) => {
            if (typeof setActions === 'function') {
              return <ActionsMenu list={setActions(row)} />;
            }
            const rowValue = translationPath
              ? t(`${translationPath}.${get(row, value)}`)
              : get(row, value);

            return <Text>{rowValue}</Text>;
          },
          header: label ?? ' ',
          hasSorting: !!sort,
          align: setActions ? 'right' : align,
        })
      ),
    [columns, t]
  );
};

export default useColumns;
