import kebabCase from 'lodash/kebabCase';
import { useCallback, useState } from 'react';
import { SurveyResponse } from '../../../shared/types';
import { useFetchApi } from '../../../hooks';
import JSZip from 'jszip';
import { Attachment } from '../../../shared/types/Attachment';

const doDownload = (data: Blob, filename: string) => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(data);
  a.href = url;
  document.body.appendChild(a);
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

type FullSurveyResponse = SurveyResponse & {
  respondent: object;
  survey: object;
  attachments: object;
};

export const useDownloadDocument = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchBlob = useFetchApi('blob');
  const fetch = useFetchApi();
  const download = useCallback(
    async (surveyResponseId: string) => {
      try {
        setError(false);
        setLoading(true);
        const surveyResponse: FullSurveyResponse = await fetch(
          `survey-responses/${surveyResponseId}?includes=survey,respondent,attachments`
        );

        const pdfBlob = await fetchBlob(
          `survey-responses/${surveyResponse.id}/pdf`
        );

        const attachedFiles = await Promise.all(
          (surveyResponse.attachments ?? []).map(async (attachment) => {
            const attachmentWithProbablyFileUrl: Attachment = await fetch(
              `attachments/${attachment.id}?presign=true`
            );
            if (attachmentWithProbablyFileUrl.fileUrl) {
              // the file url is not within our backend but directly pointing to S3 currently. Too bad we don't have an API gateway that could easily proxy it so we don't leave our base url.
              const res = await window.fetch(
                attachmentWithProbablyFileUrl.fileUrl
              );
              if (!res.ok) throw new Error('Fetch failed.');
              return {
                blob: await res.blob(),
                filename: attachmentWithProbablyFileUrl.filename,
              };
            }
            return null;
          })
        );

        const filenameNoExt = `${kebabCase(
          surveyResponse.respondent.name
        )}-${kebabCase(surveyResponse.survey.name)}-${
          surveyResponse.countryCode
        }`;

        const zip = new JSZip();
        zip.file(`${filenameNoExt}.pdf`, pdfBlob);
        attachedFiles
          // FIXME: TS 4.9.5 is not clever enough to automatically remove null from the type after this filter. TS 5.7.2 is though.
          .filter((x): x is { blob: Blob; filename: string } => x !== null)
          .forEach((blob) => zip.file(blob.filename, blob.blob));
        const file = await zip.generateAsync({ type: 'blob' });
        doDownload(file, `${filenameNoExt}.zip`);
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading]
  );
  return {
    error,
    loading,
    download,
  };
};
