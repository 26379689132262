import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SurveyResponse, UpdateSurveyResponseDto } from '../shared/types';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';

export const useSurveyResponse = (surveyResponseId?: string) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['survey-response', surveyResponseId],
    queryFn: () =>
      fetch(
        `survey-responses/${surveyResponseId}?includes=survey,attachments`
      ) as Promise<SurveyResponse>,
    enabled: !!surveyResponseId,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const updateMutation = useMutation({
    mutationKey: ['survey-response', surveyResponseId],
    mutationFn: (payload: UpdateSurveyResponseDto) =>
      fetch(`survey-responses/${surveyResponseId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }) as Promise<SurveyResponse>,
  });
  const updateSurveyResponse = (payload: UpdateSurveyResponseDto) =>
    updateMutation.mutateAsync(payload, {
      onSuccess: async (surveyResponse) => {
        // TODO TEUDR-1941 this is wrong. the response data of the mutation does not contain `.survey` and `.respondent`!
        // This is a problem for functionality that absolutely requires respondent and survey to exist, like the pdf download. The table may be all right since we fall back to null if the path does not exist. And once the refetch of survey responses (triggered by queryClient.invalidateQueries) finishes, we are in a good state again. But in the meantime... The same holds for loading an individual survey response. There it's visible when you save a survey response, the heading vanishes (because it contains data of surveyResponse.survey which is undefined).
        queryClient.setQueryData(
          ['survey-responses'],
          (prev: SurveyResponse[] | undefined) =>
            prev?.map((sr) =>
              sr.id === surveyResponse.id ? surveyResponse : sr
            )
        );
        queryClient.setQueryData(
          ['survey-response', surveyResponse.id],
          surveyResponse
        );
        queryClient.invalidateQueries({ queryKey: ['survey-responses'] });
        queryClient.invalidateQueries({
          queryKey: ['survey-response', surveyResponse.id],
        });
      },
      onError: apiError,
    });

  const submitSurveyResponse = (
    payload: Pick<UpdateSurveyResponseDto, 'status'>
  ) =>
    updateMutation.mutateAsync(payload, {
      onSuccess: async (surveyResponse) => {
        // TODO TEUDR-1941 this is wrong. the response data of the mutation does not contain `.survey` and `.respondent`!
        // This is a problem for functionality that absolutely requires respondent and survey to exist, like the pdf download. The table may be all right since we fall back to null if the path does not exist. And once the refetch of survey responses (triggered by queryClient.invalidateQueries) finishes, we are in a good state again. But in the meantime...
        queryClient.setQueryData(
          ['survey-responses'],
          (prev: SurveyResponse[] | undefined) =>
            prev?.map((sr) =>
              sr.id === surveyResponse.id ? surveyResponse : sr
            )
        );
        queryClient.setQueryData(
          ['survey-response', surveyResponse.id],
          surveyResponse
        );
        queryClient.invalidateQueries({ queryKey: ['survey-responses'] });
      },
      onError: apiError,
    });

  return {
    data,
    isLoading,
    isFetching,
    updateSurveyResponse: {
      isLoading: updateMutation.isLoading,
      execute: updateSurveyResponse,
    },
    submitSurveyResponse: {
      isLoading: updateMutation.isLoading,
      execute: submitSurveyResponse,
    },
  };
};

// TODO TEUDR-1088 remove. currently useful for development without backend
// const fakeSurveyResponseFetch = async (): Promise<SurveyResponse> => {
//   return {
//     id: 'sr-12345678',
//     countryCode: 'DE',
//     respondentId: '12345678',
//     status: 'DRAFT',
//     surveyId: '1234567',
//     json: {},
//     updatedAt: '2024-11-22T14:07:58Z',
//     createdAt: '2024-11-22T14:07:58Z',
//   };
// };
