import { DataGrid, Flex, Text } from '@liveeo/component-library';
import { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIntlApi } from '../../../hooks';
import { SurveyResponse } from '../../../shared/types';
import { DocumentIcon } from '../components/DocumentIcon';
import { OwnerCell } from '../components/DocumentsTableOwnerCell';
import { DocumentsActionsMenu } from '../DocumentsActionsMenu';
import { useDownloadDocument } from './useDownloadDocument';

export const useDocumentsTableColumns = () => {
  const { t } = useTranslation();
  const { getLocalizedCountry, getLocalizedDatetimeFormat } = useIntlApi();
  const navigate = useNavigate();
  const { download } = useDownloadDocument();

  const columns = useMemo(
    () =>
      [
        {
          header: t('documents.table.headers.name'),
          accessor: 'survey.name',
          hasSorting: false,
          cell: ({ getValue }) => (
            <Flex>
              <DocumentIcon />
              <Text pl="sm">{getValue()}</Text>
            </Flex>
          ),
        },
        {
          header: t('documents.table.headers.country'),
          accessor: 'countryCode',
          hasSorting: false,
          cell: ({ getValue }) => {
            // not using column.fallback here because in some language a translation of 'n/a' may coincidentally match a country code and thus getLocalizedCountry may return its localized country name.
            const countryCode = (getValue() ?? '') as string;
            const countryName = getLocalizedCountry(
              countryCode,
              t<string>('documents.table.values.countryCodeNotAssigned')
            );
            return <Text>{countryName}</Text>;
          },
        },
        {
          header: t('documents.table.headers.owner'),
          accessor: 'respondent.name',
          hasSorting: false,
          cell: ({ getValue, row }) => (
            <OwnerCell surveyResponse={row} text={getValue()} />
          ),
        },
        {
          header: t('documents.table.headers.status'),
          accessor: 'status',
          hasSorting: false,
          cell: ({ getValue }) => (
            <Text>
              {t(
                `documents.table.values.status.${(
                  getValue() as SurveyResponse['status']
                ).toLowerCase()}`
              )}
            </Text>
          ),
        },
        {
          header: t('documents.table.headers.updatedAt'),
          accessor: 'updatedAt',
          hasSorting: false,
          cell: ({ getValue }) => (
            <Text>
              {getLocalizedDatetimeFormat(
                getValue() as SurveyResponse['updatedAt'],
                { dateStyle: 'medium' }
              )}
            </Text>
          ),
        },
        {
          header: '',
          accessor: 'id', // we don't care for the accessor in this case
          hasSorting: false,
          cell: ({ row }: { row: SurveyResponse }) => (
            <DocumentsActionsMenu
              item={row}
              onDownload={() => download(row.id)}
              onEdit={() => navigate(`/documents/${row.id}`)}
              onView={() => navigate(`/documents/${row.id}`)}
            />
          ),
        },
      ] satisfies ComponentProps<typeof DataGrid>['columns'],
    [t, download, getLocalizedCountry, navigate, getLocalizedDatetimeFormat]
  );
  return columns;
};
