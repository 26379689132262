import { useMemo } from 'react';
import { Beta } from '@liveeo/component-library';
import {
  Component,
  Transaction,
  TransactionStatus,
} from '../../../shared/types';
import { ActionButton, StatusButton } from '../Components';
import { useTranslation } from 'react-i18next';
import { useIntlApi } from '../../../hooks';

interface TransactionColumnsProps {
  align: 'center' | 'left' | 'right';
  header: string;
  accessor: string;
  hasSorting: boolean;
  cell?: (props: { row: { id: string } & Transaction }) => JSX.Element;
}

export const useTransactionColumns = (): TransactionColumnsProps[] => {
  const { t } = useTranslation();
  const { getLocalizedDatetimeFormat } = useIntlApi();
  return useMemo(
    () => [
      {
        align: 'left',
        header: t('transactions.columns.customId'),
        accessor: 'customId',
        hasSorting: false,
      },
      {
        align: 'left',
        header: t('transactions.columns.activity'),
        accessor: 'activityType',
        hasSorting: true,
        cell: ({
          row: { activityType, id },
        }: {
          row: { id: string } & Transaction;
        }) => (
          <Beta.Text ta="left">
            {t(`transactions.activity.${activityType}`)}
          </Beta.Text>
        ),
      },
      {
        align: 'left',
        header: t('transactions.columns.components'),
        accessor: 'components',
        hasSorting: false,
        cell: ({
          row: { components, id },
        }: {
          row: { id: string } & Transaction;
        }) => (
          <div>
            {components?.map((component: Component, index: number) => (
              <Beta.Text ta="left" key={index}>
                {`${component.hsn}: ${component.commodity} (${component.quantity} ${component.unit})`}
              </Beta.Text>
            ))}
          </div>
        ),
      },
      {
        align: 'left',
        header: t('transactions.columns.status'),
        accessor: 'status',
        hasSorting: true,
        cell: ({
          row: { status = 'PENDING' as TransactionStatus, id },
        }: {
          row: { id: string } & Transaction;
        }) => <StatusButton status={status} />,
      },
      // TODO use submission date instead of createdAt
      {
        align: 'left',
        header: t('transactions.columns.submissionDate'),
        accessor: 'updatedAt',
        hasSorting: true,
        cell: ({ row: { updatedAt, status } }: { row: Transaction }) => (
          <Beta.Text ta="left">
            {!!updatedAt &&
              status !== 'DRAFT' &&
              getLocalizedDatetimeFormat(new Date(updatedAt), {
                dateStyle: 'medium',
              })}
            {status === 'DRAFT' && <>----</>}
          </Beta.Text>
        ),
      },
      {
        align: 'right',
        header: '',
        accessor: 'actions',
        hasSorting: false,
        cell: ({ row }: { row: { id: string } & Transaction }) => {
          const rawStatus =
            typeof row.status === 'object' && row.status !== null
              ? (row.status as any).props?.status
              : row.status;
          return <ActionButton id={row.id} status={rawStatus} />;
        },
      },
    ],
    []
  );
};
