import React, { createContext, useContext, useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Plot } from '../shared/types';
import { useFetchApi, useNotification } from '../hooks';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

interface PlotsContextProps {
  data: Plot[] | undefined;
  isLoading: boolean;
  error: any;
}

const PlotsContext = createContext<PlotsContextProps | undefined>(undefined);

export const PlotsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();
  const { t } = useTranslation();

  const LIMIT = 250;

  const { data, error, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['plots'],
      queryFn: ({ pageParam = 1 }) =>
        fetch(
          `plots?limit=${LIMIT}&page=${pageParam}&includes=suppliers,mostRecentAnalysesByType`
        ),
      getNextPageParam: (lastPage, pages) => {
        return lastPage.length === LIMIT ? pages.length + 1 : undefined;
      },
      onError: apiError,
      refetchOnWindowFocus: false,
    });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (hasNextPage) {
      notifications.show({
        id: 'plots-loading',
        title: t('plots.loading.title'),
        message: t('plots.loading.message'),
        loading: true,
        autoClose: false,
      });
    } else {
      notifications.hide('plots-loading');
    }
  }, [hasNextPage, t]);

  const allPlots = data?.pages.flat() || [];

  const value = {
    data: allPlots,
    error,
    isLoading,
  };

  return (
    <PlotsContext.Provider value={value}>{children}</PlotsContext.Provider>
  );
};

export const usePlotsContext = () => {
  const context = useContext(PlotsContext);
  if (!context) {
    throw new Error('usePlotsContext must be used within a PlotsProvider');
  }
  return context;
};
