import { Beta, Button, Flex, Menu } from '@liveeo/component-library';
import {
  ActivityType,
  Transaction,
  TransactionStatus,
} from '../../../shared/types';
import { useTranslation } from 'react-i18next';
import classes from '../../../shared/styles/Menu.module.css';

export interface FilterState {
  sort?: string;
  search?: string;
  title?: string;
  activity?: ActivityType;
  product?: string;
  status?: TransactionStatus;
}

interface FiltersBarProps {
  onFilterChange: (filters: Partial<FilterState>) => void;
  transactions: Transaction[];
  selectedFilters?: Partial<FilterState>;
}
const flexStyle = { top: '20px', left: 0, right: 0 };

interface FiltersItemProps {
  onFilterChange: (filters: Partial<FilterState>) => void;
  fitleredData: (string | ActivityType | TransactionStatus)[];
  selectedFilters?: Partial<FilterState>;
  filterKey: keyof FilterState;
  translateItems?: boolean;
}

const FilterItem = ({
  onFilterChange,
  fitleredData,
  selectedFilters,
  filterKey,
  translateItems = true,
}: FiltersItemProps) => {
  const { t } = useTranslation();
  const handleFilterClose = () => {
    onFilterChange({ [filterKey]: undefined });
  };

  return (
    <Menu offset={2} position="bottom-start" additionalClassNames={classes}>
      <Menu.Target>
        <Button
          c={selectedFilters?.[filterKey] ? 'primary' : undefined}
          size="sm"
          variant={selectedFilters?.[filterKey] ? undefined : 'outline'}
        >
          {t(
            selectedFilters?.[filterKey] ??
              `transactions.filters.${filterKey}.title`
          )}
          {selectedFilters?.[filterKey] && (
            <Beta.Icon icon="close" size="sm" onClick={handleFilterClose} />
          )}
        </Button>
      </Menu.Target>
      <Menu.Dropdown bg="dark.9" opacity={0.9}>
        <Menu.Item onClick={() => onFilterChange({ [filterKey]: undefined })}>
          {t(`transactions.filters.${filterKey}.all`)}
        </Menu.Item>
        {fitleredData.map((item) => (
          <Menu.Item
            key={item}
            onClick={() => onFilterChange({ [filterKey]: item })}
          >
            {translateItems
              ? t(`transactions.${filterKey}.${item}`)
              : item.toString()}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export const FiltersBar: React.FC<FiltersBarProps> = ({
  onFilterChange,
  transactions,
  selectedFilters,
}) => {
  const activities = [...new Set(transactions?.map((t) => t.activityType))];
  const products = [
    ...new Set(
      transactions
        ?.flatMap((t) => t.components?.map((c) => c.commodity) || [])
        ?.filter((commodity): commodity is string => commodity !== undefined)
    ),
  ];
  const statuses = [
    ...new Set(
      transactions
        ?.map((t) => t.status)
        ?.filter((status): status is TransactionStatus => status !== undefined)
    ),
  ].map(String);

  return (
    <Flex
      justify="center"
      align="center"
      gap="xs"
      pos="absolute"
      style={flexStyle}
    >
      <FilterItem
        onFilterChange={onFilterChange}
        fitleredData={activities}
        selectedFilters={selectedFilters}
        filterKey="activity"
      />
      <FilterItem
        onFilterChange={onFilterChange}
        fitleredData={products}
        selectedFilters={selectedFilters}
        filterKey="product"
        translateItems={false}
      />
      <FilterItem
        onFilterChange={onFilterChange}
        fitleredData={statuses}
        selectedFilters={selectedFilters}
        filterKey="status"
        translateItems={false}
      />
    </Flex>
  );
};
