type NamePair = {
  scientificName?: string;
  commonName?: string;
};

export type Component = {
  id?: string;
  commodity?: string;
  description: string;
  hsn: string;
  namePairs?: NamePair[];
  quantity: number;
  supplierBusiness: { id: string };
  unit: string;
};

export enum ActivityType {
  /** product is produced or manufactured within the Union. */
  DOMESTIC = 'DOMESTIC',
  /** product leaves the Union market and is declared for ‘export’ at the Customs. */
  EXPORT = 'EXPORT',
  /** product enters the Union market and is declared for ‘release for free circulation’ at the Customs. */
  IMPORT = 'IMPORT',
  /** product is made available on the market (EUDR_User_Guide_EO_v0.2). */
  TRADE = 'TRADE',
}

export enum TransactionStatus {
  AVAILABLE = 'AVAILABLE',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  TO_SUBMIT = 'TO_SUBMIT',
  WITHDRAWN = 'WITHDRAWN',
}

export enum OperatorType {
  OPERATOR = 'OPERATOR',
  REPRESENTING_OPERATOR = 'REPRESENTING_OPERATOR',
  REPRESENTING_TRADER = 'REPRESENTING_TRADER',
  TRADER = 'TRADER',
}

export interface Transaction {
  activityType: ActivityType;
  components: Component[];
  countryOfActivity: string;
  updatedAt?: string;
  customId: string;
  id?: string;
  operatorType?: OperatorType;
  status?: TransactionStatus;
}

export interface HsCode {
  commodity: string;
  description: string;
  hsCode: string;
  parentHsCode: string;
}

export type TransactionFormData = {
  activityType: ActivityType;
  components: Component[];
  countryOfActivity: string;
  customId: string;
  selectedHsCodes: string[];
  status: TransactionStatus;
};
