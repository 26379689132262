import { useState } from 'react';

const useSort = <T>(data: T[]) => {
  const [sort, setSort] = useState<string>('');

  const handleSort = (columnValue: string | number | symbol) => {
    setSort(String(columnValue));
  };

  const sortedData = [...data].sort((a: T, b: T) => {
    if (!sort) return 0;

    const field = sort.startsWith('-') ? sort.substring(1) : sort;
    const direction = sort.startsWith('-') ? -1 : 1;

    const aValue = a[field as keyof T];
    const bValue = b[field as keyof T];

    if (!aValue || !bValue) return 0;

    if (typeof aValue === 'string') {
      return direction * aValue.localeCompare(bValue as string);
    }

    return direction * (aValue > bValue ? 1 : -1);
  });

  return {
    sortedData,
    handleSort,
    sort,
  };
};

export default useSort;
